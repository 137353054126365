import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import nextCookies from 'next-cookies';

import { CookiesType } from '@propTypes/cookiesType';
import { principalType } from '@propTypes/userTypes';
import ApiLpc from '@services/http/ApiLpc';

import { axiosClient, getUrl } from '../../http/Http';

const baseUrl = process.env.BASE_URL;
const signIn = (body, ctx?: string) => axiosClient(ctx).post(ApiLpc.AUTH.SIGN_IN, JSON.stringify(body));
const signOut = (ctx?: string) => axiosClient(ctx).post(ApiLpc.AUTH.SIGN_OUT);

const sendSms = (email: string, ctx?: string) => axiosClient(ctx).post(getUrl(ApiLpc.AUTH.SEND_SMS), email);

const forgottenPassword = (body, ctx?: string) =>
    axiosClient(ctx).post(ApiLpc.AUTH.PASSWORD_FORGOTTEN, JSON.stringify(body));

const passwordChangeConfirm = (body, ctx?: string) =>
    axiosClient(ctx).post(ApiLpc.AUTH.PASSWORD_CHANGE_CONFIRM, JSON.stringify(body));

const googleConnect = (body, ctx?: string) => axiosClient(ctx).post(ApiLpc.AUTH.GOOGLE_CONNECT, JSON.stringify(body));

const initGoogleAuthenticator = (ctx?: string) =>
    axiosClient(ctx)
        .get(ApiLpc.AUTH.GOOGLE_AUTHENTICATOR)
        .then((res) => {
            return res.data;
        });

const initGoogleAuthenticatorIdentified = (ctx?: string) =>
    axiosClient(ctx)
        .get(ApiLpc.AUTH.GOOGLE_AUTHENTICATOR_IDENTIFIED)
        .then((res) => {
            return res.data;
        });

const connectUserAfter2faConfig = (ctx?: string) =>
    axiosClient(ctx)
        .get(ApiLpc.AUTH.SIGN_IN_AFTER_2FA_CONFIG)
        .then((res) => {
            return res.data;
        });
const downloadBackupCodes = (ctx?: string) =>
    axiosClient(ctx)
        .get(ApiLpc.AUTH.DOWNLOAD_BACKUP_CODES)
        .then((res) => {
            return res.data;
        });

const getBackupCodes = (ctx?: string) => {
    return axiosClient(ctx).get(ApiLpc.AUTH.GET_BACKUP_CODES);
};

const initSmsAuthenticator = (body, ctx?: string) =>
    axiosClient(ctx)
        .post(ApiLpc.AUTH.SMS_AUTH, JSON.stringify(body))
        .then((res) => {
            return res.data;
        });

const initSmsAuthenticatorIdentified = (body, ctx?: string) =>
    axiosClient(ctx)
        .post(ApiLpc.AUTH.SMS_AUTH_IDENTIFIED, JSON.stringify(body))
        .then((res) => {
            return res.data;
        });

const facebookConnect = (body, ctx?: string) => axiosClient(ctx).post(ApiLpc.AUTH.FACEBOOK_CONNECT, body);

const createAccount = (body, ctx?: string) => axiosClient(ctx).post(ApiLpc.AUTH.CREATE_ACCOUNT, JSON.stringify(body));

const submitMissingInformations = (body, ctx?: string) =>
    axiosClient(ctx).post(ApiLpc.AUTH.MISSING_INFORMATIONS, JSON.stringify(body));

const verifyPassword = (body, ctx?: string) => axiosClient(ctx).post(ApiLpc.AUTH.VERIFY_PASSWORD, JSON.stringify(body));

const verifyEmail = (body, ctx?: string) => axiosClient(ctx).post(ApiLpc.AUTH.VERIFY_EMAIL, body);

const resendAuthSms = (ctx?: string) => axiosClient(ctx).post(ApiLpc.AUTH.RESEND_SMS);

const resendSms = (isConnected: boolean, email?: string, ctx?: any) => {
    if (isConnected) {
        return axiosClient(ctx).post(ApiLpc.AUTH.RESEND_SMS);
    }
    if (email) {
        return axiosClient(ctx).post(getUrl(ApiLpc.AUTH.RESEND_SMS), email);
    }
    return axiosClient(ctx).post(ApiLpc.AUTH.RESEND_SMS_IDENTIFIED);
};

const validateSmsTfa = (body, ctx?: string) => axiosClient(ctx).post(ApiLpc.AUTH.VALIDATE_SMS, JSON.stringify(body));

const validateSmsTfaIdentified = (body, ctx?: string) =>
    axiosClient(ctx).post(ApiLpc.AUTH.VALIDATE_SMS_IDENTIFIED, JSON.stringify(body));

const deactivateTfa = (body, ctx?: string) => axiosClient(ctx).post(ApiLpc.AUTH.DEACTIVATE_TFA, JSON.stringify(body));

const sendTfaCode = (ctx?: string) => axiosClient(ctx).post(ApiLpc.AUTH.SEND_TFA_CODE);

const validateGoogleAuthTfa = (body, ctx?: string) =>
    axiosClient(ctx).post(ApiLpc.AUTH.VALIDATE_GOOGLE_AUTH, JSON.stringify(body));

const validateGoogleAuthTfaIdentified = (body, ctx?: string) =>
    axiosClient(ctx).post(ApiLpc.AUTH.VALIDATE_GOOGLE_AUTH_IDENTIFIED, JSON.stringify(body));

const userHasPots = (ctx?: string) => axiosClient(ctx).get(ApiLpc.AUTH.USER_HAS_POTS);

const getConsentCookie = (): CookiesType => {
    const token = Cookies.get('cnil-cookie');
    if (token) {
        return jwtDecode(token);
    }
    return null;
};
const getKycCookie = () => {
    const token = Cookies.get('kyc-cookie');
    if (token) {
        return token;
    }
    return null;
};

const sendConsentCookie = (body: CookiesType, ctx?: string) =>
    axiosClient(ctx).post(ApiLpc.AUTH.COOKIES_CONSENT, JSON.stringify(body));

const getAuth = (): principalType => {
    const token = Cookies.get('session-cookie');
    if (token) {
        return jwtDecode(token);
    }
    return null;
};

const getIdentifiedPerson = (): any => {
    const token = Cookies.get('tfa-activation-cookie');
    if (token) {
        return jwtDecode(token);
    }
    return null;
};

const getIdentifiedPersonSSR = (ctx: any): any => {
    const token = nextCookies(ctx)['tfa-activation-cookie'];
    if (token) {
        return jwtDecode(token);
    }
    return null;
};

const getUser = (ctx) => {
    const tokenCookie = nextCookies(ctx)['session-cookie'];

    if (ctx && tokenCookie) {
        return jwtDecode(tokenCookie);
    }
    return null;
};

const getBoUser = (ctx) => {
    const tokenCookie = nextCookies(ctx).boAuthCookie;

    if (ctx && tokenCookie) {
        return jwtDecode(tokenCookie);
    }
    return null;
};

const getMissingInformationsUser = () => {
    const token = Cookies.get('session-cookie-informations-missing');
    if (token) {
        return jwtDecode(token);
    }
    return null;
};

const deleteMissingInformationsCookie = () => {
    Cookies.set('session-cookie-informations-missing', '', { expires: 0, path: '', domain: baseUrl });
};

const getUserAccount = (ctx?: string) => axiosClient(ctx).get(ApiLpc.AUTH.USER_ACCOUNT);

const setMangoPayCguStatus = (status: string, ctx?: string) => {
    return axiosClient(ctx).put(ApiLpc.AUTH.MANGOPAY_CGU_STATUS, status);
};

const authService = {
    signIn,
    signOut,
    forgottenPassword,
    passwordChangeConfirm,
    getAuth,
    getUser,
    getBoUser,
    getMissingInformationsUser,
    deleteMissingInformationsCookie,
    googleConnect,
    facebookConnect,
    createAccount,
    submitMissingInformations,
    getUserAccount,
    sendConsentCookie,
    getConsentCookie,
    getKycCookie,
    userHasPots,
    initGoogleAuthenticator,
    verifyPassword,
    verifyEmail,
    resendSms,
    validateGoogleAuthTfa,
    validateSmsTfa,
    initSmsAuthenticator,
    resendAuthSms,
    downloadBackupCodes,
    getBackupCodes,
    getIdentifiedPerson,
    initGoogleAuthenticatorIdentified,
    initSmsAuthenticatorIdentified,
    validateSmsTfaIdentified,
    deactivateTfa,
    sendTfaCode,
    validateGoogleAuthTfaIdentified,
    getIdentifiedPersonSSR,
    sendSms,
    connectUserAfter2faConfig,
    setMangoPayCguStatus,
};

export default authService;
